import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from '../utils';

const allData = ServiceData;


const Features = () => {

    const designData = allData.filter(data => slugify(data.cate ? data.cate : "") === "design");
    const developmentData = allData.filter(data => slugify(data.cate ? data.cate : "") === "development");
    const marketingData = allData.filter(data => slugify(data.cate ? data.cate : "") === "online-marketing");
    const businessData = allData.filter(data => slugify(data.cate ? data.cate : "") === "business");
    const technologyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "technology");
    const strategyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "content-strategy");

    const round = [
        {
            title: 'Customer Handshake API',
            description: 'Comprehensive, encrypted API with over 70 fields to pass information from any system to VideoKYC and ensure the Video KYC Agent has all the necessary customer information.',
            image: 'handshake_image_url'
        },
        {
            title: 'Products Name Setting',
            description: 'Mentioning the product name in the API allows automatic template and rule engine selection so that you can use the same API and processes for various products.',
            image: 'products_image_url'
        },
        {
            title: 'Language',
            description: 'Option to select a specific agent group for each incoming customer, basis their preferred language, using a simple setting in the CH-API.',
            image: 'language_image_url'
        },
        {
            title: 'Agent Group selection',
            description: 'Option to select a specific agent group for each incoming customer, basis product, city, expertise etc.',
            image: 'agent_group_image_url'
        },
        {
            title: 'Priority',
            description: 'Option to give specific customers a higher priority in the customer waiting queue. Example: Preferred customers, senior citizens, existing customers etc.',
            image: 'priority_image_url'
        }
    ];

    const journy = [
        {
            title: 'Continuous Journey',
            description: 'For customers who wish to do Video KYC immediately after they have filled the product form.',
            image: 'continuous_journey_image_url'
        },
        {
            title: 'Universal Weblink',
            description: 'For customers who wish to do Video KYC anytime at a later stage by accessing a web URL.',
            image: 'universal_weblink_image_url'
        },
        {
            title: 'Agent-Assisted Journey',
            description: 'For onboarding customers using field teams and RMs, wherein the RMs can initiate and complete the Video KYC process with the customer directly.',
            image: 'agent_assisted_journey_image_url'
        },
        {
            title: 'Five User Journeys for eKYC',
            description: '1. New and Fresh Customer\n2. New Customer with PAN Data\n3. Customer who dropped after doing eKYC, and eKYC is Expired\n4. Customer who dropped after doing eKYC, and eKYC is Valid\n5. Customer marked ‘Unable’ from VKYC',
            image: 'five_user_journeys_image_url'
        },
        {
            title: 'Integrated eKYC Journey',
            description: 'Aadhaar and PAN Card API integration and validation for an integrated eKYC Independent Journey Workflow.',
            image: 'integrated_ekyc_image_url'
        }
    ];

    const queue = [
        {
            title: 'Permissible Time Limit Check',
            description: 'To check if the wait time of the customer is beyond a specific limit and if the customer should be diverted to any other channel or asked to try back later.',
            image: 'time_limit_check_image_url'
        },
        {
            title: 'Instructions Page',
            description: 'A detailed page with instructions shown to the customer before the start of the process. This can be used to remind them to carry the right documents, a blank paper, keep headphones handy, etc.',
            image: 'instructions_page_image_url'
        },
        {
            title: 'Wait Time Calculation and Page',
            description: 'A comprehensive algorithm to calculate the exact waiting time per customer and show a reverse clock to the customer. The time is refreshed real-time as customers drop out or get added to the queue.',
            image: 'wait_time_calculation_image_url'
        },
        {
            title: 'Switch to Compatible Browsers',
            description: 'Customers who arrive from a non-compatible browser are shown a \'Copy Link\' option, so they can copy the link of the session and continue the journey from a compatible browser.',
            image: 'compatible_browsers_image_url'
        },
        {
            title: 'SMS agent-specific link to customer',
            description: 'RM or agent can SMS a link to the customer directly, which will connect the customer to that specific employee only.',
            image: 'sms_agent_link_image_url'
        }
    ];

    const agentData = [
        {
            title: 'LDAP Login for Agents',
            description: 'Integrated with AD and LDAP so that agents do not have to maintain a separate username and password.',
            image: 'ldap_login_image_url'
        },
        {
            title: 'Chat Connection with Agent',
            description: 'A web socket-based text chat connection between the customer and the agent ensures communication before the video call starts, especially in cases of low bandwidth or connection drops.',
            image: 'chat_connection_image_url'
        },
        {
            title: 'Automated Welcome Message',
            description: 'An automated welcome message sent from the agent\'s side to the customer, carrying greetings and pointers for the call.',
            image: 'welcome_message_image_url'
        },
        {
            title: 'Pass Additional Customer Information to Agent',
            description: 'Customers arriving from non-compatible browsers are given the option to copy the session link to continue from a compatible browser.',
            image: 'additional_info_image_url'
        },
        {
            title: 'Show Both Customer Addresses',
            description: 'Customer\'s communication and permanent addresses displayed to the agent for instant verification, especially if incomplete or incorrect.',
            image: 'customer_addresses_image_url'
        }
    ];

    const videoCallData = [
        {
            title: 'Two-way video call',
            description: 'Option for an agent to do a two-way video call wherein both the customer and the agent can see each other.',
            image: 'two_way_call_image_url'
        },
        {
            title: 'One way video call',
            description: 'Option for an agent to do a one-way video call wherein the agent can see the customer, but the customer cannot see the agent.',
            image: 'one_way_call_image_url'
        },
        {
            title: 'Video call without agent camera option',
            description: 'Option for an agent to do a video call without having a web camera attached to the device.',
            image: 'no_camera_call_image_url'
        },
        {
            title: 'Merge multiple video calls into a single video file',
            description: 'In case of multiple disconnected video calls required to complete the process, the system merges them into a single video file.',
            image: 'merge_video_calls_image_url'
        }
    ];

    const kycFeaturesData = [
        {
            title: 'Video KYC Template',
            description: 'A template designed as per RBI regulations, configured with all mandatory steps on the agent side.',
            image: 'kyc_template_image_url'
        },
        {
            title: 'Question Verification Module',
            description: 'Random question verification module displaying PAN/Aadhaar data for the agent to confirm with a "Match/Mismatch" option.',
            image: 'question_verification_image_url'
        },
        {
            title: 'User Face, PAN and Signature Photo Capture and Tagging',
            description: 'Three buttons allowing the agent to capture the customer\'s face, PAN Card, and signature using front and back cameras.',
            image: 'photo_capture_image_url'
        },
        {
            title: 'Location capture using Google Maps API integration',
            description: 'Customers can share their location with the agent during the video call. Agents receive latitude-longitude and a live Google Map link.',
            image: 'location_capture_image_url'
        },
        {
            title: 'PAN Card data check',
            description: 'Population of data from the NDSL database in the PAN Card screen with a "Match/Mismatch" option against every field.',
            image: 'pan_data_check_image_url'
        },
        {
            title: 'Face Match using AI services from AWS or Azure',
            description: 'AI-based face comparison between "Aadhaar photo VS Live photo" and "PAN Card photo VS Live photo" in a single click.',
            image: 'face_match_image_url'
        },
        {
            title: 'NSDL – PAN – Aadhaar name match check',
            description: 'An "Acceptable/Not Acceptable" check between customer names received from NSDL, PAN, and Aadhaar.',
            image: 'name_match_check_image_url'
        }
    ];

    const imageFeaturesData = [
        {
            title: 'Watermarking on Images',
            description: 'Automatically adds a watermark on images with employee name, time stamp, and customer name.',
            image: 'watermark_image_url'
        },
        {
            title: 'Low-Res and High-Res Photo option',
            description: 'Option for an agent to take photos in high or low resolution depending on available bandwidth and throughput.',
            image: 'resolution_option_image_url'
        },
        {
            title: 'Primary Image Selection',
            description: 'Agents can take multiple photos for each category, automatically tagging one image as \'Primary\'.',
            image: 'primary_image_selection_image_url'
        },
        {
            title: 'Bandwidth Meter',
            description: 'Live tracking and display of bandwidth for both agent and customer sides to make informed decisions based on call quality.',
            image: 'bandwidth_meter_image_url'
        },
        {
            title: 'Call Summary',
            description: 'Complete log of all verifications performed by the agent, serving as a verification and processing record.',
            image: 'call_summary_image_url'
        },
        {
            title: 'Configurable Rule Engine for Agent Action',
            description: 'Ability to configure a rule engine based on verified questions, face match percentage, PAN field matches, signatures, addresses, etc.',
            image: 'rule_engine_image_url'
        }
    ];

    const closureData = [
        {
            title: 'Agent Closure',
            description: 'Three options for an agent to dispose of a KYC: \'Successful\', \'Unable\', and \'Reject\'.',
            image: 'agent_closure_image_url'
        },
        {
            title: 'Option on How to Handle \'Unable\' KYCs',
            description: 'Agent options on how to handle \'Unable Cases\': allowing them to return for Video KYC or assigning them an OTP account.',
            image: 'unable_kyc_handling_image_url'
        },
        {
            title: 'Customer Redirection basis Video KYC Closure',
            description: 'Redirecting customers to separate pages based on KYC results like \'Successful\', \'Unable\', and \'Rejected\'.',
            image: 'redirection_based_on_closure_image_url'
        },
        {
            title: 'Sending Result Parameter in Redirection URL',
            description: 'Adding metadata to the redirection URL along with the result flag for further workflows like Net Banking or Fund Transfer.',
            image: 'redirection_result_parameter_image_url'
        },
        {
            title: 'Agent Dispositions',
            description: 'Agents can add custom dispositions when marking a KYC as \'Rejected\' or \'Unable\', aiding in more detailed analysis of KYC results.',
            image: 'agent_dispositions_image_url'
        }
    ];

    const kycBankData = [
        {
            title: 'KYC Bank',
            description: 'A universal \'KYC Bank\' storing all agent and auditor processed KYCs, searchable via tracking ID, and linked to customer KYC pages.',
            image: 'kyc_bank_image_url'
        },
        {
            title: 'Customer KYC Page',
            description: 'A single web page housing complete customer details, metadata, and media assets such as photo, PAN card, Aadhaar photo, signature, and complete video recording.',
            image: 'customer_kyc_page_image_url'
        },
        {
            title: 'Concurrent Auditor Module',
            description: 'Module for assigning KYCs to concurrent auditors on a FIFO basis, allowing actions like \'Reopen\', \'Approve\', and \'Not Approve\'.',
            image: 'concurrent_auditor_module_image_url'
        },
        {
            title: 'Auditor Configuration',
            description: 'Selection of auditor groups based on product, language, and location.',
            image: 'auditor_configuration_image_url'
        },
        {
            title: 'RBI Inspector Role',
            description: 'An independent and temporary role configured for an RBI Inspector to view selected KYCs.',
            image: 'rbi_inspector_role_image_url'
        }
    ];
    const monitoringData = [
        {
            title: 'Live Call Monitor',
            description: 'Dashboard providing live status of calls with connected agents and customers.',
            image: 'live_call_monitor_image_url'
        },
        {
            title: 'Agent Login Monitor',
            description: 'Dashboard monitoring all logged-in agents and their call status.',
            image: 'agent_login_monitor_image_url'
        },
        {
            title: 'Customer Queue',
            description: 'Dashboard displaying the queue of waiting customers along with their average wait time.',
            image: 'customer_queue_image_url'
        },
        {
            title: 'Master KYC Report',
            description: 'Detailed downloadable Excel report encompassing complete KYC data, including customer data, agent actions, verifications, auditor actions, timestamps, etc.',
            image: 'master_kyc_report_image_url'
        },
        {
            title: 'Agent / Auditor Productivity Report',
            description: 'Daily report detailing the number of KYCs processed by agents or auditors, along with a breakup of \'Success\' and \'Rejected\'.',
            image: 'productivity_report_image_url'
        },
        {
            title: 'User Journey Data',
            description: 'Comprehensive report mapping the entire user journey from Customer Handshake API pass to KYC closure, detailing events at each stage.',
            image: 'user_journey_report_image_url'
        },
        {
            title: 'Google Analytics / Tag Manager Integration',
            description: 'Integration allowing addition of Google code to track user patterns, time spent, and drop-outs.',
            image: 'google_analytics_integration_image_url'
        }
    ];


    const syncServiceData = [
        {
            title: 'Data Sync Service',
            description: 'Service to transmit data back to multiple systems such as CRM and Core Banking.',
            image: 'data_sync_service_image_url'
        },
        {
            title: 'Document Upload Service',
            description: 'Service to transmit collected documents (PAN Card, Photo, Signature) back to the system.',
            image: 'document_upload_service_image_url'
        },
        {
            title: 'Merge Signature and Photo',
            description: 'Utility to merge Signature and Photo into a single image, then upload it to a system for transfer to the Branch Network.',
            image: 'merge_signature_photo_image_url'
        }
    ];

    const customizationData = [
        {
            title: 'Template Customization for Multiple Products',
            description: 'Ability to create separate process templates for various products such as Credit Card, Personal Loan, Current Account, NRI Account, etc.',
            image: 'template_customization_image_url'
        },
        {
            title: 'Additional Document Collection in Master KYC Report',
            description: 'Provision to collect and tag additional documents from the customer, such as Address Proof, VISA, Passport, within the Master KYC Report.',
            image: 'additional_documents_image_url'
        }
    ];




    console.log(designData)


    return (
        <>
        <SEO title="Service One" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne/>
                <div style={{paddingTop: "13vw"}}/>
                <div className="container">
                    <SectionTitle
                        subtitle="Accelerating Trust with a Complete Suite of Proof of Verification Services"
                        title="Features"
                        description="In an era of digital interaction, establishing trust is paramount. VideoVerifi brings you a comprehensive suite of Proof of Verification services designed to instill confidence, reliability, and security into your verification processes. Whether it's verifying addresses or confirming identities, we offer a versatile solution to meet your unique needs."
                        textAlignment="heading-left"
                        textColor=""
                    />

                </div>
                <div style={{paddingTop: "7vw"}}/>
                <div className="service-scroll-navigation-area">


                    <div className="section section-padding" id="section1">
                        <div className="container">
                            <SectionTitle
                                subtitle=""
                                title="Welcome to VideoVerifi – Where Security Meets Innovation!"
                                description="VideoVerifi leverages advanced AI Face Recognition technology to fortify every stage of your user journey. From account registration to access control, onboarding, and identity verification, our solution ensures the highest standards of security and reliability. Say goodbye to unauthorized access and streamline your processes with confidence."
                                textAlignment="heading-left"
                                textColor=""

                            />
                            {/*<div className="row">*/}
                            {/*    <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={round}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="section section-padding bg-color-light" id="section2">
                        <div className="container">
                            <SectionTitle
                                subtitle=""
                                title="Liveness Detection & AI Mapping for Robust Verification:"
                                description="Our AI Face Recognition goes beyond static images. With Liveness Detection and AI Mapping, VideoVerifi ensures robust identity verification by dynamically analyzing facial movements and features. This proactive approach adds an extra layer of security, making impersonation virtually impossible"
                                textAlignment="heading-left"
                                textColor=""

                            />

                        </div>
                    </div>

                    <div className="section section-padding" id="section3">
                        <div className="container">
                            <SectionTitle
                                subtitle=""
                                title="3D Depth, Skin Texture analysis:"
                                description="VideoVerifi delves into the intricacies of facial biometrics, incorporating 3D depth, skin texture, and microexpression analysis. This multi-dimensional scrutiny ensures unparalleled accuracy in identity verification, even in the most challenging conditions. Experience next-level precision with our advanced facial recognition technology."
                                textAlignment="heading-left"
                                textColor=""

                            />

                        </div>
                    </div>

                    <div className="section section-padding bg-color-light" id="section4">
                        <div className="container">
                            <SectionTitle
                                subtitle=""
                                title=" Powerful AI & ML Algorithms for Enhanced Face Verification Services:"
                                description="VideoVerifi is powered by cutting-edge AI and ML algorithms, continuously learning and evolving to adapt to the ever-changing landscape of identity verification. Our technology is not just advanced; it's intelligent. Benefit from enhanced face verification services that stay ahead of the curve, providing you with the confidence you need in every verification."
                                textAlignment="heading-left"
                                textColor=""

                            />
                        </div>
                    </div>

                    <div className="section section-padding" id="section5">
                        <div className="container">
                            <SectionTitle
                                subtitle=""
                                title=" Automated Solution to Eliminate the Risk of Human Error:"
                                description="Human errors can introduce vulnerabilities. VideoVerifi eliminates this risk by offering a fully-automated solution. Our AI Face Recognition technology ensures consistent and accurate results without the margin for error associated with manual processes. Trust in the reliability of automation for foolproof identity verification."
                                textAlignment="heading-left"
                                textColor=""

                            />
                        </div>
                    </div>

                    <div className="section section-padding bg-color-light" id="section6">
                        <div className="container">
                            <SectionTitle
                                subtitle=""
                                title=" Increase Conversion Rates:"
                                description="In the digital realm, user experience is paramount. VideoVerifi not only prioritizes security but also elevates the user experience. The seamless and swift identity verification pr"
                                textAlignment="heading-left"
                                textColor=""

                            />
                        </div>
                    </div>

                </div>
                <CtaLayoutOne/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default Features;