import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';


const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Choose VideoVerifi for a Future of Trustworthy Verifications!"
                    title="Values That Define VideoVerifi"
                    description="These four values are the guiding principles that run through the very core of VideoVerifi. They reflect our commitment to excellence, security, and the relentless pursuit of trust in every proof of verification service we offer."
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Security-Centric Approach</Accordion.Header>
                            <Accordion.Body>
                                At VideoVerifi, security is not just a feature; it's the bedrock of our identity. We prioritize the highest standards of security in every aspect of our proof of verification services. Your trust is our commitment, and we go above and beyond to safeguard it.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Innovation in Verification</Accordion.Header>
                            <Accordion.Body>
                                Embrace the future with VideoVerifi's commitment to innovation. We continually explore and integrate cutting-edge technologies, ensuring that our proof of verification services remain at the forefront of industry advancements. Stay ahead with our forward-thinking approach.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe />User-Centric Design</Accordion.Header>
                            <Accordion.Body>
                                A seamless user experience is fundamental to our values. VideoVerifi is designed with users in mind, ensuring that the verification process is not only secure but also user-friendly. Simplify onboarding and foster positive user interactions with our intuitive design.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaGlobe />Transparency and Real-Time Insights</Accordion.Header>
                            <Accordion.Body>
                                Transparency is non-negotiable at VideoVerifi. We believe in keeping our clients informed in real-time. Track verifications, monitor progress, and access insights seamlessly. Your access to information is key to building trust, and we prioritize it in every interaction.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/banner/5 1.png"} alt="Office" />
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

    )
}

export default WhyChooseOne;