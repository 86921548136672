import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        "id": 1,
        "title": "A Holistic Approach to Proof of Verification",
        "para": "VideoVerifi is not just a service; it's a comprehensive approach to proof of verification. Our suite covers all aspects, ensuring a holistic verification process that leaves no room for uncertainty."
    },
    {
        "id": 2,
        "title": "Document Agnosticism: Accept Any Kind of Document for Verification",
        "para": "Flexibility is key, and VideoVerifi embraces it wholeheartedly. Our platform accepts any type of document for verification, whether it's government IDs, utility bills, or other official documents. This adaptability ensures that you can cater to a diverse user base without compromise."
    },
    {
        "id": 3,
        "title": "Real-Time Tracking of Verifications",
        "para": "Stay in control with real-time tracking of all verifications performed. Our platform provides instant updates, allowing you to monitor the verification progress seamlessly. Transparency is at the core of our service, ensuring that you're always informed."
    },
    {
        "id": 4,
        "title": "Address Verification Services",
        "para": "VideoVerifi specializes in address verification, offering a robust solution to authenticate the physical location of your users. Enhance the accuracy of your records and build trust by confirming the legitimacy of addresses in real time."
    },
    {
        "id": 5,
        "title": "Identity Verification Services",
        "para": "Identity verification is the cornerstone of trust in digital transactions. VideoVerifi excels in providing identity verification services, leveraging advanced technologies like AI and facial recognition to ensure the authenticity of user identities."
    },
    {
        "id": 6,
        "title": "Seamless User Experience",
        "para": "We understand the importance of a seamless user experience. VideoVerifi is designed to integrate seamlessly into your existing processes, ensuring that verification doesn't become a hurdle for your users. Simplify onboarding and enhance user satisfaction."
    },
    {
        "id": 8,
        "title": "Compliance-Driven Solutions",
        "para": "We prioritize compliance, ensuring that our proof of verification services align with international standards and regulations. Rest assured that your verification processes are not just efficient but also fully compliant."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Valus"
                    title="Why Choose VideoVerifi?"
                    description="VideoVerifi is more than a service; it's your partner in establishing trust globally. Embrace our complete suite of proof of verification services and elevate your verification processes to new heights. Connect with us to explore how VideoVerifi can transform your approach to trust and security. Your peace of mind starts here!"
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;