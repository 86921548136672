import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { PiRecordFill } from "react-icons/pi";
import { useLocation, useParams } from "react-router";
import RecordRTC from "recordrtc";
import Swal from "sweetalert2";
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import config from "../config";
import { useReload } from "../context/ReloadProvider";
import { useSocket } from "../context/SocketProvider";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import peer from "../service/peer";
import "./common.scss";

const RoomPage = () => {
  const socket = useSocket();
  const location = useLocation();
  const { meetingId } = useParams();
  const { role, previousURL } = location.state;
  const { reload } = useReload();
  const canvasRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [remoteSocketId, setRemoteSocketId] = useState(null);
  const [userStream, setMyStream] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const [disableReady, setDisableReady] = useState(false);
  const [isAudioMuted, setAudioMuted] = useState(false);
  const [isVideoMuted, setVideoMuted] = useState(false);
  const [isAdminJoined, setAdminJoined] = useState(false);
  const [isUserJoined, setUserJoined] = useState(false);
  const [meetingIsEndedSuccessfully, setMeetingIsEndedSuccessfully] =
    useState(false);
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [webRTCConnectionStates, setWebRTCConnectionStates] = useState(peer?.peer?.connectionState)


  useEffect(() => {
    setWebRTCConnectionStates(peer?.peer?.connectionState)
  }, [peer?.peer?.connectionState])



  console.log("webRTCConnectionStates==========", webRTCConnectionStates)
  const handleUserJoined = useCallback(({ email, id }) => {
    setRemoteSocketId(id);
    if (email.split("_")[0] === "user") {
      setUserJoined(true);
    } else if (email.split("_")[0] === "admin") {
      setAdminJoined(true);
    }
  }, []);

  const handleCallUser = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    const offer = await peer.getOffer();
    socket.emit("user:call", { to: remoteSocketId, offer });
    await setMyStream(stream);
    await setAdminJoined(false);

  }, [remoteSocketId, socket]);

  const handleIncomingCall = useCallback(
    async ({ from, offer }) => {
      setRemoteSocketId(from);
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setMyStream(stream);
      setUserJoined(true);
      const ans = await peer.getAnswer(offer);
      socket.emit("call:accepted", { to: from, ans });
    },
    [socket]
  );

  const sendStreams = useCallback(() => {
    // for (const track of userStream.getTracks()) {
    //   peer?.peer?.addTrack(track, userStream);
    // }

    const audioTrack = userStream.getAudioTracks()[0];
    const videoTrack = userStream.getVideoTracks()[0];

    if (!isAudioMuted) {
      peer?.peer?.addTrack(audioTrack, userStream);
    }

    if (!isVideoMuted) {
      peer?.peer?.addTrack(videoTrack, userStream);
    }
    setDisableReady(true);
  }, [userStream]);

  const handleCallAccepted = useCallback(
    ({ from, ans }) => {
      peer.setLocalDescription(ans);
      sendStreams();
      if (role === "admin") {
        setUserJoined(false);
      }
    },
    [sendStreams]
  );

  const handleNegoNeeded = useCallback(async () => {
    const offer = await peer.getOffer();
    socket.emit("peer:nego:needed", { offer, to: remoteSocketId });
  }, [remoteSocketId, socket]);

  const handleNegoNeedIncoming = useCallback(
    async ({ from, offer }) => {
      const ans = await peer.getAnswer(offer);
      socket.emit("peer:nego:done", { to: from, ans });
    },
    [socket]
  );

  const handleNegoNeedFinal = useCallback(async ({ ans }) => {
    await peer.setLocalDescription(ans);
  }, []);

  const handleCallEndedSuccessfully = useCallback(
    async (isSuccess) => {
      if (isSuccess) {
        setMeetingIsEndedSuccessfully(isSuccess);
        disconnectWebRTC();

        if (role === "user") {
          setTimeout(() => {
            window.location = "/";
          }, 3000);
        }
      }
    },
    [role]
  );

  const handleBeforeUnload = (e) => {
    if (role === "user" && socket) {
      socket.emit("changeMeetingActiveStatus", {
        meetingId,
        status: false,
      });
    }
  };

  // Handle audio mute/unmute
  const handleAudioToggle = () => {
    const audioTrack = userStream.getAudioTracks()[0];
    audioTrack.enabled = !audioTrack.enabled;
    setAudioMuted(!isAudioMuted);
  };

  // Handle video mute/unmute
  const handleVideoToggle = () => {
    const videoTrack = userStream.getVideoTracks()[0];
    videoTrack.enabled = !videoTrack.enabled;
    setVideoMuted(!isVideoMuted);
  };

  const handleRestartMeeting = () => {
    window.location = "/";
  };

  const handleEndKyc = useCallback(async () => {
    if (socket) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger me-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You want to end the eKYC!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
          allowOutsideClick: false
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            socket.emit("end:meeting", {
              to: remoteSocketId,
              meetingId: meetingId,
            });

            if (!recordedBlob) {
              recorder.stopRecording(async () => {
                setIsRecording(false);

                const blob = recorder.getBlob();
                await uploadRecording(blob);
              });
            } else {
              await uploadRecording();
            }

            // swalWithBootstrapButtons.fire({
            //   title: "Ended!",
            //   text: "eKYC has been ended Successfully!",
            //   icon: "success",
            // });
          }
        });
    }
  }, [recorder, recordedBlob, socket]);

  // Function to disconnect the WebRTC connection
  const disconnectWebRTC = () => {
    // Check if the RTCPeerConnection exists
    if (peer?.peer) {
      // Close the RTCPeerConnection
      peer.peer.close();

      // Optionally, set it to null or undefined
      // to indicate that the connection has been closed
      peer.peer = null;

      // Check if there's a local media stream
      if (userStream) {
        // Stop all tracks in the media stream
        userStream.getTracks().forEach((track) => track.stop());

        // Optionally, set it to null or undefined
        // to indicate that the media stream has been stopped
        setMyStream(null);
      }
    }
  };

  const startRecording = () => {
    setRecordedBlob(null);
    recorder.startRecording();
    setIsRecording(true);
  };

  const stopRecording = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to Stop Recording!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false
      })
      .then((result) => {
        if (result.isConfirmed) {
          recorder.stopRecording(() => {
            setIsRecording(false);

            const blob = recorder.getBlob();
            setRecordedBlob(blob);
          });

          swalWithBootstrapButtons.fire({
            title: "Successfully!",
            text: "Recording stoped Successfully!",
            icon: "success",
          });
        }
      });
  };

  const uploadRecording = async (blob = recordedBlob) => {
    try {
      const formData = new FormData();
      formData.append("meetingId", meetingId);
      formData.append("file", blob, `${meetingId}.mp4`);

      // Display Swal with progress bar
      Swal.fire({
        title: "Uploading...",
        html: "Meeting ended successfully! Please wait...",
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await axios.post(
        `${config.REACT_API_URL}api/upload-recording`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            Swal.update({
              html: `Uploading... ${percentCompleted}%`,
              allowOutsideClick: false,
              showConfirmButton: false,
            });
          },
        }
      );

      // Close Swal and show success message
      await Swal.fire({
        icon: "success",
        title: "Upload Complete",
        text: "Recording uploaded successfully!",
        confirmButtonText: "OK",
        allowOutsideClick: false
      }).then(() => {
        if (role === "admin") {
          window.location = "/";
        }
      });
    } catch (error) {
      console.error("Error uploading recording:", error);

      // Show error message
      Swal.fire({
        icon: "error",
        title: "Upload Failed",
        text: "There was an error uploading the recording.",
        allowOutsideClick: false
      });
    }
  };

  const downloadRecording = () => {
    if (recordedBlob) {
      const url = URL.createObjectURL(recordedBlob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "recorded-video.mp4"; // You can customize the file name
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  // Draw both video streams onto a canvas
  const drawStreamsOnCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const userVideo = document.createElement("video");
    userVideo.srcObject = userStream;

    const screenVideo = document.createElement("video");
    screenVideo.srcObject = remoteStream;

    Promise.all([userVideo.play(), screenVideo.play()]).then(() => {
      canvas.width = Math.max(userVideo.videoWidth, screenVideo.videoWidth);
      canvas.height = Math.max(userVideo.videoHeight, screenVideo.videoHeight);

      ctx.drawImage(userVideo, 0, 0, canvas.width, canvas.height);
      ctx.drawImage(screenVideo, 0, 0, canvas.width, canvas.height);

      userVideo.pause();
      screenVideo.pause();
    });
  };

  useEffect(() => {
    if (!reload) {
      window.location = previousURL;
    }

    peer?.peer.addEventListener("connectionstatechange", () => {
      const connectionStatus = peer?.peer?.connectionState;
      console.log(connectionStatus, "connectionStatus========");
      setWebRTCConnectionStates(connectionStatus)

      if (["disconnected", "failed", "closed"].includes(connectionStatus)) {
        setShowModal(true);
      }
    });

    peer?.peer?.addEventListener("track", async (ev) => {
      const remoteStream = ev.streams;
      setRemoteStream(remoteStream[0]);
    });
    if (role === "user" && socket) {
      socket.emit("changeMeetingActiveStatus", {
        meetingId,
        status: true,
      });
    }
    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleBeforeUnload);

    // Detach the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("user:joined", handleUserJoined);
      socket.on("incoming:call", handleIncomingCall);
      socket.on("call:accepted", handleCallAccepted);
      socket.on("peer:nego:needed", handleNegoNeedIncoming);
      socket.on("peer:nego:final", handleNegoNeedFinal);
      socket.on("meeting:ended", handleCallEndedSuccessfully);
    }
    return () => {
      if (socket) {
        socket.off("user:joined", handleUserJoined);
        socket.off("incoming:call", handleIncomingCall);
        socket.off("call:accepted", handleCallAccepted);
        socket.off("peer:nego:needed", handleNegoNeedIncoming);
        socket.off("peer:nego:final", handleNegoNeedFinal);
        socket.off("meeting:ended", handleCallEndedSuccessfully);
      }
    };
  }, [
    socket,
    handleUserJoined,
    handleIncomingCall,
    handleCallAccepted,
    handleNegoNeedIncoming,
    handleNegoNeedFinal,
    handleCallEndedSuccessfully,
  ]);

  useEffect(() => {
    if (!meetingIsEndedSuccessfully) {
      peer?.peer?.addEventListener("negotiationneeded", handleNegoNeeded);
      return () => {
        peer?.peer?.removeEventListener("negotiationneeded", handleNegoNeeded);
      };
    }
  }, [handleNegoNeeded]);

  useEffect(() => {
    if (userStream && remoteStream) {
      const mediaRecorder = new RecordRTC([userStream, remoteStream], {
        type: "video",
        mimeType: "video/mp4",
      });

      setRecorder(mediaRecorder);
    }
  }, [userStream, remoteStream]);

  useEffect(() => {
    if (userStream && remoteStream) {
      drawStreamsOnCanvas();
    }
  }, [userStream, remoteStream]);

  console.log(" peer?.peer?.connectionState", peer?.peer?.connectionState)
  return (
    <div>
      <SEO title="Service One" />
      <ColorSwitcher />
      <main className="main-wrapper">
        {/* eKYC Restart Alert Modal*/}
        <Modal show={showModal} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>Something went wrong please restart the eKYC.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleRestartMeeting}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <HeaderOne />
        <div className="service-scroll-navigation-area">
          <div className="section section-padding" id="section1">
            <div className="container">
              <h4>KYC Verification Process</h4>
              <h4>
                {remoteSocketId &&
                  role === "user" &&
                  webRTCConnectionStates !== "connected" &&
                  "Waiting for admin join..."}
              </h4>
              <h4>
                {role === "admin" &&
                  !isUserJoined &&
                  webRTCConnectionStates !== "connected" &&
                  "Waiting for customer to accept join request..."}
              </h4>
              {userStream && !disableReady && false && (
                <button
                  className="axil-btn btn-fill-primary meeting-btn"
                  onClick={sendStreams}
                >
                  Start
                </button>
              )}
              {remoteSocketId && isAdminJoined && (
                <button
                  className="axil-btn btn-fill-primary mb-4 meeting-btn"
                  onClick={handleCallUser}
                >
                  Start eKYC
                </button>
              )}
              {remoteSocketId && isUserJoined && role === "admin" && (
                <button
                  className="axil-btn btn-fill-primary meeting-btn mb-4"
                  onClick={handleCallUser}
                >
                  Click To Start eKYC
                </button>
              )}

              <div className="row">
                {!meetingIsEndedSuccessfully ? (
                  <>
                    <div className="col-6 pr-8">
                      {userStream && (
                        <div className="counterup-progress counterup-style-2 video-view">
                          <h5>You</h5>
                          <video
                            autoPlay
                            playsInline
                            muted
                            ref={(videoRef) => {
                              if (videoRef) videoRef.srcObject = userStream;
                            }}
                            height="100%"
                            width="100%"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-6 pl-8">
                      {remoteStream && (
                        <div className="counterup-progress counterup-style-2 video-view">
                          <h5>{role === "user" ? "KYC Admin" : "Customer"}</h5>
                          <video
                            autoPlay
                            playsInline
                            ref={(videoRef) => {
                              if (videoRef) videoRef.srcObject = remoteStream;
                            }}
                            height="100%"
                            width="100%"
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="col-12">
                    <div className="counterup-progress counterup-style-2 video-kyc-done">
                      <h6>eKYC Completed.</h6>
                    </div>
                  </div>
                )}

                {console.log("================================", remoteStream,
                  role === "admin",
                  webRTCConnectionStates === "connected",
                  !meetingIsEndedSuccessfully)}
                {remoteStream &&
                  role === "admin" &&
                  webRTCConnectionStates === "connected" &&
                  !meetingIsEndedSuccessfully && (
                    <>
                      <div className="text-center">
                        {
                          recordedBlob && 
                          <button
                            className="axil-btn btn-fill-primary meeting-btn w-auto me-2 mb-2"
                            onClick={handleEndKyc}
                          >
                            Complete eKYC
                          </button>
                        }
                        {!isRecording && (
                          <button
                            className="axil-btn btn-fill-primary meeting-btn w-auto me-2 mb-2"
                            onClick={startRecording}
                            disabled={isRecording}
                          >
                            Start Recording
                          </button>
                        )}

                        {isRecording && !recordedBlob && (
                          <button
                            className="axil-btn btn-fill-primary meeting-btn w-auto me-2 mb-2"
                            onClick={stopRecording}
                            disabled={!isRecording}
                          >
                            <PiRecordFill
                              color="red"
                              size={21}
                              className=" recording-btn "
                            />{" "}
                            Stop Recording
                          </button>
                        )}
                        {recordedBlob && (
                          <button
                            className="axil-btn btn-fill-primary meeting-btn w-auto mb-2"
                            onClick={downloadRecording}
                            disabled={!recordedBlob}
                          >
                            Download Recording
                          </button>
                        )}
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>

        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        <FooterOne parentClass="" />
      </main>
    </div>
  );
};

export default RoomPage;
