import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ReloadProvider } from './context/ReloadProvider';
import { SocketProvider } from './context/SocketProvider';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <SocketProvider>
    <ReloadProvider>
      <App />
    </ReloadProvider>
  </SocketProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
