import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import TeamOne from "../component/team/TeamOne";



const AboutUse = () => {

    return (
        <>
        <SEO title="Blog Grid"/>
        <ColorSwitcher/>
            <main className="main-wrapVideoKYC is a simple product.
What VideoKYC does may be called revolutionary.per">
                <HeaderOne/>
                <BreadCrumbOne
                    title="VideoVerifi"
                    page="About us"
                />

                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <h4
                                    style={{zIndex: 100, padding: "3vw 0", textAlign: "left"}}
                                    className='contact-subheader'>Transforming Identity Verification</h4>
                                <h5 className='contact-subheader'
                                    style={{fontWeight: "lighter", zIndex: 100, textAlign: "left"}}> Experience the
                                    fusion of AI and ML technologies in Video KYC, ensuring a secure and efficient
                                    process. Enhance the
                                    reliability of identity verification with the added assurance of our established
                                    address
                                    verification services.</h5>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                            <img
                                src={process.env.PUBLIC_URL + "/images/about/About us 2.png"} alt="Bubble"/>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="section section-padding">
                    <div className="container">
                        <p className="body-font1">
                            At Adsum Advisory Services Private Limited, we take pride in our legacy of over 15 years,
                            delivering unparalleled services in address verification across the diverse landscape of
                            India. Drawing on this extensive experience, we introduce VideoVerifi – a revolutionary
                            Video KYC solution designed to redefine the standards of identity verification.
                        </p>

                    </div>
                    <ul className="list-unstyled shape-group-12">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                                                           alt="Bubble"/></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                                                           alt="Bubble"/></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                                                           alt="Circle"/></li>
                    </ul>
                </div>
                <TeamOne allData={[
                    {
                        "title": "Pankaj Kumar",
                        "thumb": "/images/team/team-1.jpeg",
                        "designation": "Co-founder",
                        "description": "Pankaj Kumar is a seasoned professional and co-founder of Adsum Advisory Services Private Limited, bringing over a decade of expertise to the realms of insurance, investigation, risk management, and pioneering advancements in the loan verification process. Pankaj embarked on his professional journey in 2009, demonstrating a commitment to excellence and innovation from the outset.  Over the years, he has carved a niche for himself as a visionary leader with a comprehensive understanding of the intricate domains of insurance, investigation, and risk management."
                    },
                    {
                        "title": "RAM MOOTHATU",
                        "thumb": "/images/team/team-2.jpeg",
                        "designation": "Director- New Business Initiative",
                        "description": "Ram, has over 23 years of experience in Credit, Collection and risk management. Prior Joining ADSUM he worked for various Corporates believes in highest levels of integrity, ethics, customer service"
                    },
                    {
                        "title": "T L SURESH KUMAR",
                        "thumb": "/images/team/team-3.jpeg",
                        "designation": "Operation Manager",
                        "description": "Suresh has been with ADSUM for 08 years. He is completely aligned with LocationTech in every pincode. He takes the opportunity to train new entrants into the ADSUM culture and mentoring teams"
                    },
                    {
                        "title": "Vineetha Wilson",
                        "thumb": "/images/team/team-4.jpeg",
                        "designation": "Marketing Manager",
                        "description": "Vineetha Wilson is a dynamic Marketing Manager at Videoverifi, leveraging her rich background in background checks, KYC (Know Your Customer), AML (Anti-Money Laundering), and related domains. With a wealth of experience and a strategic mindset, Vineetha plays a pivotal role in shaping Videoverifi's brand and communication strategies."
                    },
                    {
                        "title": "Priyanka",
                        "thumb": "/images/team/team-5.jpeg",
                        "designation": "Human Resource Manager",
                        "description": "Priyanka serves as the Human Resource Manager at Videoverifi, bringing a wealth of experience in HR and people management to the forefront of our organizational strategy. Her dedicated approach to fostering a positive and engaging workplace contributes significantly to Videoverifi's dynamic team culture."
                    }
                ]
                }/>


                {/*<div className="section section-padding bg-color-dark overflow-hidden">*/}
                {/*    <div className="container">*/}
                {/*        <SectionTitle */}
                {/*            subtitle="Find Us"*/}
                {/*            title="Our office"*/}
                {/*            description=""*/}
                {/*            textAlignment="heading-light-left"*/}
                {/*            textColor=""*/}
                {/*        />*/}
                {/*        <div className="row">*/}
                {/*            <ContactLocation />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <ul className="shape-group-11 list-unstyled">*/}
                {/*        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="line" /></li>*/}
                {/*        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="line" /></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}

                <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60"/>
            </main>
        </>
    )
}

export default AboutUse;
