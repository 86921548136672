import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';



const EKYC = () => {
    return (
        <>
            <SEO title="Blog Grid" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne/>
                <BreadCrumbOne
                    title="E-KYC"
                    page="eKYC"
                />
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <h4 className='contact-heading' style={{zIndex: 100, textAlign: "left"}}>Don't make them jump through hoops!</h4>
                                <h5 className='contact-subheader' style={{zIndex: 100, fontWeight: "lighter", padding: "2vw 0", textAlign: "left"}}>Now, a customer can do a Video KYC directly and
                                    independently, even if
                                    they haven't completed the eKYC<br/> process before or dropped the Video KYC process
                                    midway in the
                                    past.

                                    <br/><br/>A smooth journey for customers, zero integration hassles for you.</h5>
                            </div>
                            <div className="col-xl-6 col-lg-6">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container d-flex justify-content-center align-items-center">
                    {/*<Tilty perspective={2000} reset={false}>*/}
                    <img src={process.env.PUBLIC_URL + "/images/banner/E-KYC 1.png"} alt="Illustration"/>
                    {/*</Tilty>*/}
                </div>

                <div className="section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6">
                                <div className="contact-form-box shadow-box mb--30">
                                    <h3 className="title">Get a free VIdeo Verifi quote now</h3>
                                    <FormTwo/>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 offset-xl-1">
                                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                    <h4 className="title">Phone</h4>
                                    <p>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</p>
                                    <h4 className="phone-number"><a href="tel:+919866773092">(+91)9866773092</a></h4>
                                </div>
                                <div className="contact-info mb--30">
                                    <h4 className="title">Email</h4>
                                    <p>Our support team will get back to in 48-h during standard business hours.</p>
                                    <h4 className="phone-number"><a
                                        href="mailto: info@adsum.co.in"> info@adsum.co.in</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-12">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                                                           alt="Bubble"/></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                                                           alt="Bubble"/></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                                                           alt="Circle"/></li>
                    </ul>
                </div>

                {/*<div className="section section-padding bg-color-dark overflow-hidden">*/}
                {/*    <div className="container">*/}
                {/*        <SectionTitle */}
                {/*            subtitle="Find Us"*/}
                {/*            title="Our office"*/}
                {/*            description=""*/}
                {/*            textAlignment="heading-light-left"*/}
                {/*            textColor=""*/}
                {/*        />*/}
                {/*        <div className="row">*/}
                {/*            <eKYCLocation />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <ul className="shape-group-11 list-unstyled">*/}
                {/*        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="line" /></li>*/}
                {/*        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="line" /></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}

                <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60"/>
            </main>
        </>
    )
}

export default EKYC;