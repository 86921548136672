import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerOne from '../component/banner/BannerOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CounterUp from "../component/counterup/CounterUp";
import {Link} from "react-router-dom";
import FormTwo from "../component/contact/FormTwo";
import ContactLocation from "../component/contact/ContactLocation";
import AboutThree from "../component/about/AboutThree";
import WhyChooseOne from "../component/whychoose/WhyChooseOne";


const DigitalAgency = () => {

    return (
        <>
        <SEO title="Home"/>
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerOne/>
                <BrandOne/>

                <div className="section section-padding">
                    <div className="container">
                        <SectionTitle
                            subtitle=""
                            title="How the VideoKYC onboarding journey works"
                            description=""
                            textAlignment=""
                            textColor=""
                        />
                        <div className="row">
                            <CounterUp data={[
                                {
                                    id: 3,
                                    light_icon: "/images/banner/1.png",
                                    dark_icon: "/images/banner/1.png",
                                    text: "Customer Initates Video KYC Journy"
                                }, {
                                    id: 2,
                                    light_icon: "/images/banner/67.png",
                                    dark_icon: "/images/banner/67.png",
                                    text: "Face Match and liveness check"
                                }, {
                                    id: 3,
                                    light_icon: "/images/banner/47.png",
                                    dark_icon: "/images/banner/47.png",
                                    text: "ID scan and verification"
                                }, {
                                    id: 4,
                                    light_icon: "/images/banner/4.png",
                                    dark_icon: "/images/banner/4.png",
                                    text: "Results are delivered to your auditors"
                                }
                            ]}
                                       countView={false}
                                       colSize="col-lg-3 col-sm-6" layoutStyle="counterup-style-2"
                                       evenTopMargin="mt--90 mt_md--0"/>
                        </div>
                    </div>
                </div>
                <WhyChooseOne />
                {/*<ProcessOne/>*/}
                <div className="section section-padding-2 bg-color-dark">
                    <div className="container">
                        <SectionTitle
                            subtitle=""
                            title="Values That Define VideoVerifi"
                            description=""
                            textAlignment="heading-light-left"
                            textColor=""
                        />
                        <div className='row'>

                            <ServicePropOne
                                AllData={[{
                                    "image": "/images/banner/Secure Fingerprint 1.png",
                                    "title": "Security-Centric Approach",
                                    "description": "At VideoVerifi, security is not just a feature; it's the bedrock of our identity. We prioritize the highest standards of security in every aspect of our proof of verification services. Your trust is our commitment, and we go above and beyond to safeguard it.",
                                }, {
                                    "image": "/images/banner/Secure Fingerprint 2.png",
                                    "title": "Innovation in Verification",
                                    "description": "Embrace the future with VideoVerifi's commitment to innovation. We continually explore and integrate cutting-edge technologies, ensuring that our proof of verification services remain at the forefront of industry advancements. Stay ahead with our forward-thinking approach.",
                                }, {
                                    "image": "/images/banner/Website Protection 1.png",
                                    "title": "User-Centric Design",
                                    "description": "A seamless user experience is fundamental to our values. VideoVerifi is designed with users in mind, ensuring that the verification process is not only secure but also user-friendly. Simplify onboarding and foster positive user interactions with our intuitive design.",
                                }, {
                                    "image": "/images/banner/Server Protection 1.png",
                                    "title": "Transparency and Real-Time Insights",
                                    "description": "Transparency is non-negotiable at VideoVerifi. We believe in keeping our clients informed in real-time. Track verifications, monitor progress, and access insights seamlessly. Your access to information is key to building trust, and we prioritize it in every interaction.\n",
                                },]}
                                colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="6"/>
                            <h5 className={"text-white"}>
                                These four values are the guiding principles that run through the very core of VideoVerifi. They reflect our commitment to excellence, security, and the relentless pursuit of trust in every proof of verification service we offer.
                            </h5>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-10">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"}
                                                           alt="Circle"/></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"}
                                                           alt="Circle"/></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"}
                                                           alt="Circle"/></li>
                    </ul>
                </div>
                <div className="section section-padding expert-counterup-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="section-heading heading-left">
                                    {/*<span className="subtitle">Yes, we've got 5 ways to cover that too!</span>*/}
                                    <h2>E-KYC?</h2>
                                    <p className="mb--50"></p>
                                    <Link to={process.env.PUBLIC_URL + "/eKYC"} className="axil-btn btn-large btn-fill-primary">Check the full
                                        eKYC flow</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <CounterUpOne/>




                {/*<AboutOne/>*/}
                {/*<ProjectOne/>*/}
                {/*<CounterUpOne/>*/}
                {/*<TestimonialOne/>*/}
                {/*<div className="section bg-color-light section-padding">*/}
                {/*    <div className="container">*/}
                {/*        <SectionTitle*/}
                {/*            subtitle="Pricing Plan"*/}
                {/*            title="We’ve built solutions for..."*/}
                {/*            description="Flexible pricing options for freelancers and design teams."*/}
                {/*            textAlignment=""*/}
                {/*            textColor=""*/}
                {/*        />*/}
                {/*        <PricingOne/>*/}
                {/*    </div>*/}
                {/*    <ul className="list-unstyled shape-group-3">*/}
                {/*        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"}*/}
                {/*                                           alt="shape"/></li>*/}
                {/*        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}*/}
                {/*                                           alt="shape"/></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                {/*<BlogOne/>*/}
                <CtaLayoutOne/>
                <AboutThree />
                <>
                    <h1 className='contact-heading'>See to believe it!</h1>
                    <h4 className='contact-subheader'>A one-stop shop for KYC, V-CIP, and IPV over video</h4>
                    <div className="section section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-5 col-lg-6">
                                    <div className="contact-form-box shadow-box mb--30">
                                        <h3 className="title"></h3>
                                        <FormTwo/>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-6 offset-xl-1">
                                    <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                        <h4 className="title">Phone</h4>
                                        <p>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</p>
                                        <h4 className="phone-number"><a href="tel:+919866773092">(+91)9866773092</a></h4>
                                    </div>
                                    <div className="contact-info mb--30">
                                        <h4 className="title">Email</h4>
                                        <p>Our support team will get back to in 48-h during standard business hours.</p>
                                        <h4 className="phone-number"><a
                                            href="mailto: info@adsum.co.in"> info@adsum.co.in</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="list-unstyled shape-group-12">
                            <li className="shape shape-1"><img
                                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble"/></li>
                            <li className="shape shape-2"><img
                                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble"/></li>
                            <li className="shape shape-3"><img
                                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle"/></li>
                        </ul>
                    </div>

                    <div className="section section-padding bg-color-dark overflow-hidden">
                        <div className="container">
                            <SectionTitle
                                subtitle="Find Us"
                                title="Our office"
                                description=""
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            <div className="row">
                                <ContactLocation/>
                            </div>
                        </div>
                        <ul className="shape-group-11 list-unstyled">
                            <li className="shape shape-1"><img
                                src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="line"/></li>
                            <li className="shape shape-2"><img
                                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="line"/></li>
                        </ul>
                    </div>
                </>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default DigitalAgency;

