import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import SEO from "../common/SEO"
import FooterOne from "../common/footer/FooterOne"
import HeaderOne from "../common/header/HeaderOne"
import config from '../config'
import { useReload } from '../context/ReloadProvider'
import { useSocket } from '../context/SocketProvider'

const Verification = () => {
  const { role, meetingId } = useParams()
  const [meetingDetails, setMeetingDetails] = useState()
  const { reload, setReloadState } = useReload()
  const [count, setCount] = useState(10);

  const socket = useSocket()
  const navigate = useNavigate()

  useEffect(() => {
    // Create an interval to decrement the count
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        // Check if count is greater than 0 before decrementing
        const nextCount = prevCount > 0 ? prevCount - 1 : 0;

        // If count reaches 0, clear the interval to pause
        if (nextCount === 0) {
          clearInterval(intervalId);
          window.location.reload();
        }

        return nextCount;
      });
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const updateLocation = async (latitude, longitude) => {
    try {
      // Call your API to update the user's location
      const response = await axios.post(`${config.REACT_API_URL}api/update-location`, {
        meetingId,
        userLocation: {
          latitude,
          longitude,
        }
      });

      if (response && response.data) {
        setMeetingDetails(response.data)
      }

      // Handle the response as needed
      console.log("Location updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating location:", error.message);
    }
  };

  const getUserLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
      async (position) => {
        // User allowed location
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Call API to update location with latitude and longitude
        await updateLocation(latitude, longitude);

        // await fetchMeetingData()
      },
      async (error) => {
        // User denied location permission or an error occurred
        console.error("Error getting location:", error.message);

        // Show a SweetAlert blocking modal to inform the user about the need for location permission
        await Swal.fire({
          title: 'Location Access Required',
          text: 'To proceed, please allow access to your location.',
          icon: 'info',
          allowOutsideClick: false,  // Prevent clicking outside the modal to close it
          allowEscapeKey: false,     // Prevent closing the modal with the Escape key
          showCancelButton: false,   // Hide the cancel button
          showConfirmButton: false,  // Hide the confirm button
        });
      }
    );
  }

  const fetchMeetingData = async () => {
    try {
      const response = await axios.get(`${config.REACT_API_URL}api/meeting-details/${meetingId}`)

      if (response && response.data) {
        setMeetingDetails(response.data)
      }
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }

  useEffect(() => {
    if (role === "user" && navigator.geolocation) {
      getUserLocation()
    } else {
      fetchMeetingData()
    }
  }, [])

  const handlePageLoad = useCallback(
    (meeting) => {
      if (socket) {
        if (role === 'admin') {
          socket.emit('room:join', { email: meeting.adminId, room: meeting._id })
        } else {
          socket.emit('room:join', { email: meeting.userId, room: meeting._id })
        }
      }
    },
    [socket]
  )

  useEffect(() => {
    if (meetingDetails) {
      if (role === 'admin') {
        localStorage.setItem('userId', meetingDetails.adminId)
      } else {
        localStorage.setItem('userId', meetingDetails.userId)
        handlePageLoad(meetingDetails)
      }
    }
  }, [meetingDetails])

  useEffect(() => {
    if (meetingDetails && socket) {
      if (role === 'admin') {
        socket.emit('checkMeetingUsrIsActive', meetingId)
        socket.on('checkMeetingUsrIsActive', (isActive) => {
          if (isActive) {
            handlePageLoad(meetingDetails)
          } else {
            alert('User is not active')
          }
        })
      } else {
        handlePageLoad(meetingDetails)
      }
    }
  }, [meetingDetails, socket])

  const handleJoinRoom = useCallback(
    (data) => {
      const { room } = data
      setReloadState(true)

      navigate(`/verification/${room}`, { state: { meetingDetails, role, previousURL: window.location.href } })
    },
    [navigate]
  )

  useEffect(() => {
    if (socket) {
      socket.on('room:join', handleJoinRoom)
      return () => {
        socket.off('room:join', handleJoinRoom)
      }
    }
  }, [socket, handleJoinRoom, meetingDetails])

  return (

    <div>
      <SEO title="Service One" />
      <main className="main-wrapper">
        <HeaderOne />
        <div className="service-scroll-navigation-area">
          <div className="section section-padding" id="section1">
            <div className="container text-center">
              {
                <span className="loader"></span>
              }
            </div>
          </div>
        </div>
        <FooterOne parentClass="" />
      </main>
    </div>
  )
}

export default Verification
