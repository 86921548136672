import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from '../../component/counterup/CounterUp';
import {Link} from "react-router-dom";
import {FaAngleDown} from "react-icons/fa";


const CounterUpOne = () => {
    const itemList = [
        {
            id: 1,
            light_icon: "/images/banner/APIs for Data integratio.png",
            dark_icon: "/images/banner/APIs for Data integratio.png",
            countNum: 15,
            text: "APIs for data integration\n"
        },
        {
            id: 2,
            light_icon: "/images/banner/Customer queue management 1.png",
            dark_icon: "/images/banner/Customer queue management 1.png",
            countNum: 15,
            text: "Customer queue management\n"
        },
        {
            id: 3,
            light_icon: "/images/banner/Language preference for customer 1.png",
            dark_icon: "/images/banner/Language preference for customer 1.png",
            countNum: 15,
            text: "Language preference for customer"
        },
        {
            id: 4,
            light_icon: "/images/banner/Language preference for customer 2.png",
            dark_icon: "/images/banner/Language preference for customer 2.png",
            countNum: 15,
            text: "Automated welcome message"
        },
        {
            id: 5,
            light_icon: "/images/banner/Language preference for customer 3.png",
            dark_icon: "/images/banner/Language preference for customer 3.png",
            countNum: 15,
            text: "Video call at low bandwidths"
        },
        {
            id: 6,
            light_icon: "/images/banner/Language preference for customer 4.png",
            dark_icon: "/images/banner/Language preference for customer 4.png",
            countNum: 15,
            text: "Geotagging"
        },
        {
            id: 7,
            light_icon: "/images/banner/APIs for Data integration 1.png",
            dark_icon: "/images/banner/APIs for Data integration 1.png",
            countNum: 15,
            text: "OTP Validation"
        },
        {
            id: 8,
            light_icon: "/images/banner/Customer queue management 1 (1).png",
            dark_icon: "/images/banner/Customer queue management 1 (1).png",
            countNum: 15,
            text: "AWS Face Rekognition"
        },
        {
            id: 9,
            light_icon: "/images/banner/Customer queue management 2.png",
            dark_icon: "/images/banner/Customer queue management 2.png",
            countNum: 15,
            text: "Enterprise grade security"
        },
        {
            id: 10,
            light_icon: "/images/banner/Customer queue management 3.png",
            dark_icon: "/images/banner/Customer queue management 3.png",
            countNum: 15,
            text: "Live Dashboard"
        },
        {
            id: 11,
            light_icon: "/images/banner/Customer queue management 4.png",
            dark_icon: "/images/banner/Customer queue management 4.png",
            countNum: 15,
            text: "Flexible deployment models"
        },
        {
            id: 12,
            light_icon: "/images/banner/Customer queue management 5.png",
            dark_icon: "/images/banner/Customer queue management 5.png",
            countNum: 15,
            text: "Desktop, Android and iOS"
        },
        {
            id: 13,
            light_icon: "/images/banner/APIs for Data integration 1 (2).png",
            dark_icon: "/images/banner/APIs for Data integration 1 (2).png",
            countNum: 15,
            text: "Watermarking on images"
        },
        {
            id: 14,
            light_icon: "/images/banner/Customer queue management 1 (2).png",
            dark_icon: "/images/banner/Customer queue management 1 (2).png",
            countNum: 15,
            text: "Google Tag Manager Integration"
        },
        {
            id: 15,
            light_icon: "/images/banner/Customer queue management 2 (1).png",
            dark_icon: "/images/banner/Customer queue management 2 (1).png",
            countNum: 15,
            text: "Agent and Auditor Dispositions"
        }
    ];

    return (
        <div className="section section-padding bg-color-dark">
            <div className="container">
                <SectionTitle
                    subtitle=""
                    title="India's <i><b>most complete</b></i> VideoKYC solution"
                    description=""
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row">
                    <CounterUp
                        data={itemList}
                        countView={false} colSize="col-lg-2 col-6" layoutStyle="" evenTopMargin=""/>
                </div>
                {/*<Link to={"/contact"} className="axil-btn btn-large btn-fill-primary">Download Product Overview</Link>*/}

                {/*<h3 className={"text-white"}>Too many features to fit here, take a look at all 57 features*/}
                {/*    <Link className={"text-primary"} to={process.env.PUBLIC_URL + "/eKYC"}>here<FaAngleDown /></Link></h3>*/}
            </div>
        </div>
    )
}


export default CounterUpOne;