import React, { createContext, useContext, useState } from "react";

const ReloadContext = createContext();

const ReloadProvider = ({ children }) => {
  const [reload, setReload] = useState(false);

  const setReloadState = (newReloadState) => {
    setReload(newReloadState);
  };

  return (
    <ReloadContext.Provider value={{ reload, setReloadState }}>
      {children}
    </ReloadContext.Provider>
  );
};

const useReload = () => {
  const context = useContext(ReloadContext);

  if (!context) {
    console.error("useReload must be used within a ReloadProvider");
  }

  return context;
};

export { ReloadProvider, useReload };
