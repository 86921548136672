import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import eKYCLocation from '../component/contact/ContactLocation';
import CounterUp from "../component/counterup/CounterUp";



const CustomerJourny = () => {
    const data = [
        {
            "text": "The customer fills the form on the RE website, or mobile app OR the customer initiates the journey through an independent weblink.",
            "id": 1,
            "light_icon": "/images/blog/20 1.png",
            "dark_icon": "/images/blog/20 1.png"
        },
        {
            "text": "The customer completes eKYC",
            "id": 1,
            "light_icon": "/images/blog/21 2.png",
            "dark_icon": "/images/blog/21 2.png"
        },
        {
            "text": "Location Page: RE system will check the customer's location, and if in India, will proceed further.",
            "id": 3,
            "light_icon": "/images/blog/3 9.png",
            "dark_icon": "/images/blog/3 9.png"
        },
        {
            "text": "The customer is shown an instructions page",
            "id": 3,
            "light_icon": "/images/blog/20 1 (1).png",
            "dark_icon": "/images/blog/20 1 (1).png"
        },
        {
            "text": "The customer starts the Video KYC journey.",
            "id": 5,
            "light_icon": "/images/blog/21 2 (1).png",
            "dark_icon": "/images/blog/21 2 (1).png"
        },
        {
            "text": "If an agent is already assisting the journey, the customer enters the Employee ID for the agent.",
            "id": 5,
            "light_icon": "/images/blog/3 9 (1).png",
            "dark_icon": "/images/blog/3 9 (1).png"
        },
        {
            "text": "The customer gets passed to a round-robin system to get connected to an agent.",
            "id": 7,
            "light_icon": "/images/blog/20 1 (2).png",
            "dark_icon": "/images/blog/20 1 (2).png"
        },
        {
            "text": "The customer gets connected to an agent on text chat. The agent receives a notification.",
            "id": 7,
            "light_icon": "/images/blog/21 2 (2).png",
            "dark_icon": "/images/blog/21 2 (2).png"
        },
        {
            "text": "The agent initiates a two-way video call with the customer, which the customer accepts.",
            "id": 9,
            "light_icon": "/images/blog/3 9 (2).png",
            "dark_icon": "/images/blog/3 9 (2).png"
        },
        {
            "text": "The agent receives some random customer details from the form such as Name, Address, Date of Birth, etc. used for verification.",
            "id": 9,
            "light_icon": "/images/blog/20 1 (3).png",
            "dark_icon": "/images/blog/20 1 (3).png"
        },
        {
            "text": "The agent takes a screenshot of the customer's face from the live video.",
            "id": 11,
            "light_icon": "/images/blog/21 2 (3).png",
            "dark_icon": "/images/blog/21 2 (3).png"
        },
        {
            "text": "The agent takes a screenshot of the customer showing the PAN card and compares it to the PAN number entered in the form.",
            "id": 11,
            "light_icon": "/images/blog/3 9 (3).png",
            "dark_icon": "/images/blog/3 9 (3).png"
        },
        {
            "text": "The agent asks the customer to sign a blank piece of paper and hold it up to the screen. The agent takes a screenshot.",
            "id": 13,
            "light_icon": "/images/blog/20 1 (4).png",
            "dark_icon": "/images/blog/20 1 (4).png"
        },
        {
            "text": "The agent compares the customer's photo with the photo obtained from UIDAI using a Face Comparison API and checks the % match.",
            "id": 13,
            "light_icon": "/images/blog/21 2 (4).png",
            "dark_icon": "/images/blog/21 2 (4).png"
        },
        {
            "text": "The agent will close the KYC as Successful or Rejected, depending on the verification points.",
            "id": 15,
            "light_icon": "/images/blog/3 9 (4).png",
            "dark_icon": "/images/blog/3 9 (4).png"
        },
        {
            "text": "The complete interaction is recorded on video, and documents are available on a single Customer KYC page.",
            "id": 17,
            "light_icon": "/images/blog/20 1 (5).png",
            "dark_icon": "/images/blog/20 1 (5).png"
        },
        {
            "text": "All 'Successful' KYCs get added to an Auditor Bucket and get passed onto an audit team for approval.",
            "id": 17,
            "light_icon": "/images/blog/21 2 (5).png",
            "dark_icon": "/images/blog/21 2 (5).png"
        },
        {
            "text": "Video KYC system passes all the data using a Messaging Queue Service to your system.",
            "id": 17,
            "light_icon": "/images/blog/3 9 (5).png",
            "dark_icon": "/images/blog/3 9 (5).png"
        }
    ]

    return (
        <>
            <SEO title="Blog Grid" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title=""
                    page="Customer-journy"
                />
                <div className="section">
                    <div className="container">
                        <SectionTitle
                            subtitle=""
                            title="Get to Know Your Customer (KYC) in-person, remotely."
                            description="Reduce the lifecycle of a KYC process from days to hours."
                            textAlignment=""
                            textColor=""
                        />
                        <div className="row">
                            <CounterUp data={data}
                                countView={false}
                                colSize="col-lg-4 col-sm-6" layoutStyle="counterup-style-2"
                                evenTopMargin="mt--90 mt_md--0" />
                        </div>
                    </div>
                </div>

                <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default CustomerJourny;