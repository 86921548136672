import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import config from "../config";

const SocketContext = createContext(null);

export const useSocket = () => {
  const socket = useContext(SocketContext);
  if (!socket) {
    console.error("Socket Not Found");
  }
  return socket;
};

export const SocketProvider = (props) => {
  const [socket, setSocket] = useState(null);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userId) {
      const newSocket = io(config.REACT_API_URL, {
        secure: true,
        query: {
          userId,
        },
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [userId]);

  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  );
};
